import React from 'react';
import { injectIntl } from 'react-intl';
import ErrorContent from '../components/ErrorContent';
import SEO from '../components/SEO/SEO';

const Error404Page = injectIntl(() => <ErrorContent responseCode="404" />);

export default Error404Page;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.error-page.404.page-title" intl={pageContext.intl}>
    <meta name="robots" content="noindex" />
  </SEO>
);
/* eslint-enable react/prop-types */
